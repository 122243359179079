var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-1 pa-1"},[_c('v-card-title',{staticClass:"pa-1"},[_c('span',[_vm._v(_vm._s(_vm.$t("Inventarizatsiya hisoboti") + " ")+" ")]),_c('v-spacer'),_c('div',[_vm._v(" "+_vm._s(Math.round((_vm.summ.topildi * 10000) / _vm.summ.qoldiq) / 100)+" % ")]),_c('v-spacer'),_c('span',[_vm._v(_vm._s(_vm.$t("Tekshirilganlari: ") + " ")+" ")]),_vm._v(" "+_vm._s(_vm.checkedReport)+" ")],1),_c('v-card-text',{staticClass:"pb-1"},[_c('v-data-table',{staticClass:"ma-1",staticStyle:{"border":"1px solid #aaa"},attrs:{"dense":"","fixed-header":"","loading-text":_vm.$t('loadingText'),"no-data-text":_vm.$t('noDataText'),"height":_vm.screenHeight,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"id","server-items-length":_vm.server_items_length,"options":_vm.dataTableOptions,"disable-pagination":true,"footer-props":{
          itemsPerPageOptions: [50, 100, 200, -1],
          itemsPerPageAllText: _vm.$t('itemsPerPageAllText'),
          itemsPerPageText: _vm.$t('itemsPerPageText'),
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right',
        }},on:{"update:options":function($event){_vm.dataTableOptions=$event},"update:page":_vm.updatePage,"update:items-per-page":_vm.updatePerPage},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.items .map(function (x) { return x.id; }) .indexOf(item.id) + _vm.from + 1)+" ")]}},{key:"item.qoldiq",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.qoldiq ? Math.round(item.qoldiq * 100) / 100 : "")+" ")]}},{key:"item.topildi",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.topildi ? Math.round(item.topildi * 100) / 100 : "")+" ")]}},{key:"item.bajarildi",fn:function(ref){
        var item = ref.item;
return [_c('span',{style:((item.bajarildi ? Math.round(item.bajarildi * 100) / 100 : 0) >
              100
                ? 'background-color:#fcc; display:block;'
                : '')},[_vm._v(" "+_vm._s(item.bajarildi ? Math.round(item.bajarildi * 100) / 100 : "")+" ")])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"color":"blue","small":"","text":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"color":"red","small":"","text":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }